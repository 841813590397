import { PLATFORM_ENUM, FEATURES } from '@/constants';
import { OrderStatus } from 'fuel-utils/dist/order';

export type SideType = 'front' | 'back';
export type SideAxisType = 'width' | 'height';
export type MockupsType = 'flat' | 'with-model';

export enum TYPE_USERS_ENUM {
  INTERNAL = 0,
  USER = 1,
  FRAUD = 2,
  OTHER = 3,
}

export type Category =
  | 'Shirts'
  | 'Sweatshirts'
  | 'Accessories'
  | 'Mugs'
  | 'Posters'
  | 'Apparel'
  | 'Cases'
  | 'Hats'
  | 'Face-masks';
export type GroupOption = 'multiple' | 'single';

export interface PreviewMockupRequestSide {
  imageUrl: string;
  identifier: string;
  scale: { x: number; y: number };
  placement: { x: number; y: number };
}

export interface PreviewMockupRequest {
  sides: Record<string, PreviewMockupRequestSide>;
}

export interface ICategory {
  badge?: string;
  disabled?: boolean;
  displayName?: string;
  name: string;
  order: number;
  subCategories: ISubCategory[];
  value: string;
}

export interface ISubCategory {
  name: string;
  value: string;
}

export interface ImageProps {
  id: string;
  name: string;
  kind?: string;
  size?: number;
  hash?: string;
  width?: number;
  height?: number;
  ratio?: number;
  url?: string;
  error?: any;
}

export interface ArtworkProps {
  artworkId: string;
  printTypes: {
    dtg: {
      src: string;
      dimensions: {
        width: number;
        height: number;
        ratio: number;
      };
    };
    embr: {
      src: string;
      colors: string[];
      dimensions: {
        width: number;
        height: number;
        ratio: number;
      };
    };
  };
}

export enum PrintType {
  Case = 'case',
  DTG = 'dtg',
  Embroidered = 'embr',
  Mug = 'mug',
  Poster = 'poster',
  Sublimation = 'sublimation',
}

export type PrintTypeKey = 'dtg' | 'embr';

export interface DimensionsType {
  height: number;
  width: number;
}

export interface DimensionsUnitType {
  height: number;
  width: number;
  unit: 'inch' | 'percentage';
}

export interface PlacementType_Position {
  vertical: {
    origin: string;
    offset: number;
  };
  horizontal: {
    origin: string;
    offset: number;
  };
}

export interface PlacementType {
  position?: PlacementType_Position;
  size: {
    width: number;
    height: number;
    unit: 'inch' | 'percentage';
  };
}

export interface PlacementDetailsType {
  dpi?: number;
  heightInch?: number;
  position?: PlacementType_Position;
  quality?: 'poor' | 'average' | 'great';
  widthInch?: number;
}

export enum PositionEnum {
  C = 'C',
  LP = 'LP',
  LC = 'LC',
  RP = 'RP',
  RC = 'RC',
}

export enum Size {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = '2xl',
  XXXL = '3xl',
  XXXXL = '4xl',
  XXXXXL = '5xl',
  XXXXXXL = '6xl',
  Oz11 = '11oz',
  Oz15 = '15oz',
  s11x17 = '11x17',
  s17x11 = '17x11',
  s16x24 = '16x24',
  s24x16 = '24x16',
  s24x36 = '24x36',
  s36x24 = '36x24',
  s11x14 = '11x14',
  s14x11 = '14x11',
  s12x18 = '12x18',
  s18x12 = '18x12',
  s18x24 = '18x24',
  s24x18 = '24x18',
  s20x24 = '20x24',
  s24x20 = '24x20',
  s16x20 = '16x20',
  s20x16 = '20x16',
  s30x20 = '30x20',
  s20x30 = '20x30',
  s36x48 = '36x48',
  s48x36 = '48x36',
  s8x12 = '8x12',
  s12x8 = '12x8',
  s12x16 = '12x16',
  s16x12 = '16x12',
  s27x40 = '27x40',
  s40x27 = '40x27',
  ONE = 'one',
  Dash = '-',
}

export interface BleedDimensions {
  front: DimensionsType;
  back?: DimensionsType;
  left?: DimensionsType;
  right?: DimensionsType;
}

export interface ITemplate {
  front: {
    default?: string;
    flat?: string;
    [key: string]: string | undefined;
  };
  back: {
    default?: string;
    flat?: string;
    [key: string]: string | undefined;
  };
}

export interface IProductCostBySize {
  size: string;
  cost: number;
}

export interface IProductAvailability {
  color: string;
  size: string;
  hash?: string;
}

export interface ICost {
  base: number;
  sizes: [IProductCostBySize];
  extraDesign: number;
  shipping: {
    international: {
      base: number;
      extraUnit: number;
    };
    domestic: {
      base: number;
      extraUnit: number;
    };
    expeditedShipping: {
      base: number;
      extraUnit: number;
    };
  };
}

export interface IProduct {
  _id: string;
  uuid?: string;
  spIdentifier: string;
  sku: string;
  name: string;
  description: string;
  sizeChart: string[];
  colors: string[];
  url: string;
  thumbnails?: string[];
  category: Category;
  subCategory: string;
  categoryKey: string;
  templates: ITemplate;
  printType: PrintType;
  printSize: PrintType;
  bleedDimensions: BleedDimensions;
  tag: string;
  properties: {
    min: number;
    good: number;
  };
  cost: ICost;
  powerTierBaseCost?: number;
  availability: IProductAvailability[];
  expectedShippingTime: string;
  displayPriority: number;
  canExpediteShipping: boolean;
  status?: 'active' | 'inactive';
  canBeReturned?: boolean;
  designTemplate?: string;
  features?: FEATURES[];
  categories: string[];
  techniques?: string[];
  materials?: string[];
  colorEffects?: string[];
  brand?: string;
  tags?: string[];
  colorGroups?: string[];
}

export interface ILineItem {
  title: string;
  platform: PLATFORM_ENUM;
  productId: string;
  quantity: number;
  sku: string;
  variantId: string;
  vendor: string;
  color?: string;
  size?: string;
  images?: any[];
  fuelProductId?: string;
  spIdentifier: string;
  canBeReturned?: boolean;
}

export interface IShippingInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  state: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  useExpeditedShipping: boolean;
}

export interface ILineItemCost {
  designQuantity: number;
  extraDesignCost: number;
  productIdentifier: string;
  quantity: number;
  unitCost: number;
  unitExpeditedExtraCost?: number;
  variantId: string;
}

export interface ICost {
  baseCost: number;
  shippingCost: number;
  lineItems: ILineItemCost[];
  taxCost?: number;
  total: number;
}

export interface IShippingCost {
  costExpedited: number;
  costStandard: number;
}

export interface ImagePositionType {
  horizontal: {
    offset: number;
    origin: 'LC' | 'C' | 'RC' | 'L' | 'R';
  };
  vertical: {
    offset: number;
    origin: 'T' | 'C';
  };
}

export interface ImageDetailsType {
  dpi: number;
  widthInch: number;
  heightInch: number;
  aspect: number;
}

export interface PlatenType {
  height: number;
  width: number;
  offset: {
    top: number;
  };
}

export interface ImageSideOpsType {
  fileId?: string;
  position?: ImagePositionType;
  dimensions?: DimensionsUnitType;
  details?: ImageDetailsType;
  platen?: PlatenType;
}

export interface IPricedSize {
  size: string;
  price: string;
}

export interface IProductOptions {
  uuid: string;
  spIdentifier: string;
  name: string;
  description: string;
  price: string;
  sizes: Array<IPricedSize>;
  colors: string[];
  variants: number;
  collections: string[];
  tags: string[];
  category: Category | '';
  printType: PrintType;
  printSize: string;
  bleedDimensions: BleedDimensions;
  design?: string;
  sides: {
    [key: string]: ImageSideOpsType;
    front: ImageSideOpsType;
    back: ImageSideOpsType;
  };
  availability: {
    color: string;
    size: string;
  }[];
}

export interface IProductColor {
  name: string;
  hex: string;
  heather?: boolean;
  disabled?: boolean;
  product?: string;
}

export interface IColorsCatalog {
  [key: string]: IProductColor[];
}

export interface IShippingAddress {
  [key: string]: string | null;
  name: string;
  company: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  countryCode: string | null;
  phone: string | null;
}
export interface IReturnAddress {
  name?: string;
  company: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
  isDefault?: boolean;
}

export interface IFulfillment {
  variantId: string;
  fulfillmentId: string;
  trackingUrl: string;
  trackingNumber: string;
}

export interface IOrderSettings {
  expedited?: boolean;
}

export interface IOrder {
  _id: string;
  baseCost: number;
  checkoutUrl: string;
  createdAt: string;
  customerId: string;
  customerName: string;
  enableDetails: boolean;
  errors: any[]; // TODO add type
  forceFulfillment?: boolean;
  fulfillments: IFulfillment[];
  lineItems: any[]; // TODO add type
  lineItemsErrors: any[]; // TODO add type
  orderEvents: any[]; // TODO add type
  orderIdSP: string;
  orderName: string;
  paidAt: string;
  receivedAt: Date;
  shippingAddress: IShippingAddress;
  shippingCost: number;
  expeditedCost: number;
  settings?: IOrderSettings;
  orderId: string;
  shopifyId: string;
  status: OrderStatus;
  totalCost: number;
  batchId: string;
  platform: PLATFORM_ENUM;
  note: any[];
  expedited?: boolean;
  canBeOrderExpeditable: boolean;
  returnAddress?: IShippingAddress;
}

export interface IContactDetails {
  phoneNumber?: string;
  isPhoneNumberVerified?: boolean;
  facebookURL?: string;
  country?: string;
  countryCallingCode?: string;
}

export interface IIntroductoryTierOffer {
  isOffering: boolean;
  activationDate?: Date;
  orderCount?: number;
}

export interface IUserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  autoOrderProcessing?: boolean;
  coolOffPeriod?: number;
  maxmindWhitelist?: boolean;
  displayOrderStatistics?: boolean;
  planName?: string;
  planNameTitle?: string;
  createdAt?: Date;
  shopUrl?: string;
  shopDomain?: string;
  isShopifyUser?: boolean;
  isInternalStore?: boolean;
  userType?: number;
  isLinked?: boolean;
  slug?: string;
  tierName?: string;
  isExpeditedShipping?: boolean;
  contactDetails?: IContactDetails;
  introductoryTierOffer?: IIntroductoryTierOffer;
  invoiceInformation?: IInvoiceInformation;
  initialInstructions?: boolean;
  storeDeactivated?: boolean;
}

export enum EXTERNAL_PAYMENT_TYPE_ENUM {
  CREDIT_CARD_STRIPE = 'creditCard',
  CREDIT_CARD_BRAINTREE = 'creditCardBraintree',
  PAYPAL = 'PayPal',
}

export interface IBalanceInfo {
  currentBalance: number;
}

export interface IInvoiceInformation {
  name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface IPaymentInfo {
  paymentType?: EXTERNAL_PAYMENT_TYPE_ENUM;
  data?: {
    last4: string;
    brand: string;
    email: string;
  };
}

export interface ITemplateData {
  _id: string;
  lighting: number;
  blur: number;
  displace: number;
  colorAlpha: number;
  side: string;
  type: string;
  identifier: string;
  plainBlank: boolean;
  mirror: boolean;
  size: DimensionsType;
  dimensions: DimensionsType;
  success: boolean;
  bounds: {
    bottomRight: {
      x: number;
      y: number;
    };
    bottomLeft: {
      x: number;
      y: number;
    };
    topRight: {
      x: number;
      y: number;
    };
    topLeft: {
      x: number;
      y: number;
    };
  };
  images: {
    base: string;
    highlight: string;
    shadow: string;
    background: string;
  };
}

export interface ITemplateDataFail {
  identifier: string;
  success: boolean;
}

export interface IUserTemplateProduct {
  id: string;
  uuid?: string;
  name: string;
  price: string;
  description: string;
  category: string;
  printType: string;
  colors: string[];
  sizes: string[];
  collections: string[];
  tags: string[];
  bleedDimensions?: BleedDimensions;
  sides?: any;
}

export interface IUserTemplate {
  id: string;
  templateId: string;
  templateName: string;
  group: GroupOption;

  name: string;
  description: string;
  collections: string[];
  tags: string[];
  products: IUserTemplateProduct[];
}

export interface IGalleryImage {
  artworkId: string;
  fileName: string;
  fileUrl: string;
  filePreviewUrl: string;
  contentType: string;
}

export enum SortByValue {
  NewestOrder = 'newestOrder',
  OldestOrder = 'oldestOrder',
  FirstShipped = 'firstShipped',
  LastShipped = 'lastShipped',
}

export enum TimeByValue {
  Today = 'today',
  Yesterday = 'yesterday',
  LastWeek = 'lastWeek',
  ThisWeek = 'thisWeek',
  LastMonth = 'lastMonth',
  ThisMonth = 'thisMonth',
  AllTime = 'allTime',
  CustomRange = 'customRange',
}

export enum IFileRejectionReason {
  FILETYPE = 'UNSUPPORTED_FILETYPE',
  FILESIZE = 'EXCEEDED_FILESIZE',
}

export interface IFileRejection {
  reason: IFileRejectionReason;
  message: string;
  file: File;
}

export interface IFuelDesign_Sides_Side {
  artworkId: string;
  height?: number;
  horizontal?: number;
  location?: string;
  name?: string;
  offset?: number;
  vertical?: number;
  width?: number;
}

export interface IFuelDesign_Sides {
  front?: IFuelDesign_Sides_Side;
  back?: IFuelDesign_Sides_Side;
  left?: IFuelDesign_Sides_Side;
  right?: IFuelDesign_Sides_Side;
  center?: IFuelDesign_Sides_Side;
}

export interface IFuelDesign {
  _id: string;
  printType: PrintType;
  hash: string;
  sides: IFuelDesign_Sides;
  designIdSP: string;
  backgroundColor?: string;
  /** The base design from which this one was created (update or digitization) */
  parentDesign?: string;
}

export interface IEditProductResponse_StoreProduct {
  _id: string;
  /** @TODO Not used yet, add it when required */
  childrenDesigns: any[];
  copyright: {
    flagged: boolean;
    spots: {
      keyword: string;
      resource: string;
      source: string;
      spotAt: Date;
    }[];
  };
  createdAt: Date;
  description: string;
  design: IFuelDesign;
  /** @TODO Not used yet, add it when required */
  finalProducts: any[];
  fuelProductId?: string;
  /** @TODO Add interface for platforms */
  platform: { [key in PLATFORM_ENUM]: any };
  product: IProduct; // this interface may not have all the fields, but at least the required ones
  /** @deprecated Use platform.shopify.collections instead */
  shopifyCollections: number[];
  /** @deprecated Use platform.shopify.description instead */
  shopifyDescription: string;
  /** @deprecated Use platform.shopify.handle instead */
  shopifyHandle: string;
  shopifyProductId?: string;
  /** @deprecated Use platform.shopify.tags instead */
  shopifyTags: string[];
  /** @deprecated Use platform.shopify.title instead */
  shopifyTitle: string;
  /** @deprecated Use platform.shopify.type instead */
  shopifyType: string;
  title: string;
  updatedAt: Date;
  user: string;
}

export interface IPrintType {
  dimensions: { width: number; height: number };
  src: string; // Same as artwork.thumb
  artwork: {
    original: string;
    thumb: string;
    medium: string;
    regular: string;
  };
  mapped?: { hex: string; name: string }[];
}

export interface IEditProductResponse_File {
  _id: string;
  id: string; // Same as artworkId
  artworkId: string;
  userId: string;
  createdAt: Date;
  contentType: string;
  name: string;
  url: string;
  printTypes: {
    dtg: IPrintType;
    embr: IPrintType;
  };
}

export interface IEditProductResponse {
  storeProducts: IEditProductResponse_StoreProduct[];
  files: IEditProductResponse_File[];
  shopifyProduct: any;
}

export interface IOrderStatisticsResponse {
  fuelTotalCost: number;
  shopifyTotalCost: number;
  totalOrders: number;
}

export interface IBillingAddres {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  countryCode: string;
  zip: string;
  phone?: string;
}
